import React from "react"

import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { H1, Paragraph, ContentContainer } from "../components/ds"

const Page = () => {
  return (
    <>
      <SEO title="Teman vi jobbar med" />
      <Layout>
        <ContentContainer>
          <H1>Projekt - Teman vi arbetar med</H1>
          <Paragraph>
            På vår förskola arbetar vi med snällhet som smittar. Vår förskola
            ska genomsyras av ett hjärtligt och hänsynsfullt klimat. Barn och
            vårdnadshavare ska känna sig trygga och välkomna hos oss. En
            hjälpande hand och ett leende ger ringar på vattnet.
          </Paragraph>
          <Paragraph>
            Vår verksamhet bygger mycket på utevistelse. Vi följer
            årstidsväxlingarna och utforskar vår närmiljö. Vi går ofta från
            gården på promenader och till skogen. Förskolan har nära till många
            natursköna platser. Många av våra måltider äter vi ute så länge
            vädret tillåter. De aktiviteter vi gör inomhus tar vi med oss ut,
            skapande verksamhet, bokläsning, pussel och spel.
          </Paragraph>
          <Paragraph>
            Utifrån barnens utforskande och intressen arbetar vår förskola med
            olika projekt. Projekten utgår från de prioriterade målområden som
            förskolan har. Vårt arbete utgår från Läroplan för förskolan samt
            skollagen. Förskolan bedriver ett aktivt arbete mot diskriminering
            och kränkande behandling.
          </Paragraph>
          <Paragraph>
            På vår förskola vill vi erbjuda alla barn en trygg miljö som främjar
            leken, kreativiteten, det lustfyllda lärandet och social kompetens.
          </Paragraph>
          <Paragraph>
            Genom dokumentation och analys arbetar barn och personal fram nya
            ingångar och frågeställningar som i sin tur ger ny kunskap.
          </Paragraph>
          <Paragraph>Varmt välkommen till oss på Brandbilen.</Paragraph>
        </ContentContainer>
      </Layout>
    </>
  )
}

export default Page
